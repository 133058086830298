import { useEffect } from 'react';

/**
 * It relies on event.target.className === 'k-overlay' so far.
 */
export default function useClickOutside(handler: () => void) {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        event.target instanceof HTMLElement &&
        event.target.className === 'k-overlay'
      ) {
        handler();
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handler]);
}
