import * as React from 'react';
import { ajax } from 'ajax/ajax';
import { Switch } from '@progress/kendo-react-inputs';
import { useTranslation } from 'react-i18next';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { chevron_right as ChevronRight } from 'app/widgets/SVGs';
import { DeleteAccountConfirmationDialog } from './Dialogs/DeleteAccountConfirmationDialog';
import { UserSettingsContext } from 'app/components/ReactContexts/userSettingsContext';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import { Success } from 'app/components/Notifications';

export function SettingsPage() {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const { setClassNames, removeClassNames, removeSpecificClass } =
    React.useContext(LayoutContext);
  const { userSettings, setUserSettings } =
    React.useContext(UserSettingsContext);
  const { addNotification } = React.useContext(NotificationContext);
  const { t } = useTranslation();

  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  const postUpdates = (setting: any, value: any) => {
    (async () => {
      const settings = {
        settings: {
          [setting]: value,
        },
      };

      const result = await ajax.post(
        `${ajax.appBaseUrl}/api/user/settings`,
        settings,
      );
      return result;
    })().then(result => {
      if (result.status == 200) {
        addNotification(
          <Success
            text={t('profile_saved')}
            additionalClass="alert-profile-saved"
          />,
          6000,
        );
      }
    });
  };
  const handleMeasurementUnitChange = event => {
    setUserSettings({ ...userSettings, measurementUnit: event.target.value });
    postUpdates('measurementUnit', event.target.value);
  };

  const handleSubscribedToAlertsChange = event => {
    setUserSettings({
      ...userSettings,
      subscribedToAlerts: event.target.value,
    });
    postUpdates('subscribedToAlerts', event.target.value);
  };

  const handleSubscribedToMessagesChange = event => {
    setUserSettings({
      ...userSettings,
      subscribedToMessages: event.target.value,
    });
    postUpdates('subscribedToMessages', event.target.value);
  };

  const handleClick = () => {
    removeSpecificClass('mobile-nav-shown');
  };

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        setShowDeleteDialog(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <div className="relative">
      <h1>{t('profile_settings')}</h1>

      <div className="form-caption">
        <span>{t('manage_preferences')}</span>
      </div>
      <div className="box-content">
        <h3>{t('general_settings')}</h3>

        <div className="settings-item">
          <div className="settings-item_label">
            <h4>{t('units')}</h4>
          </div>
          <div className="settings-item_content">
            <div className="select-wrapper">
              <select
                value={userSettings.measurementUnit}
                onChange={handleMeasurementUnitChange}
                onClick={handleClick}
              >
                <option value="METRIC">{t('metric_unit')}</option>
                <option value="US">{t('imperial_unit')}</option>
              </select>
            </div>
          </div>
        </div>
        <div className="separator-xl"></div>
        <h3>{t('notifications')}</h3>

        <div className="settings-item">
          <div className="settings-item_label">
            <h4>{t('alerts')}</h4>
            <span>{t('informationAboutEquipment')}</span>
          </div>
          <div className="settings-item_content">
            <Switch
              checked={userSettings.subscribedToAlerts}
              onChange={handleSubscribedToAlertsChange}
              onLabel=""
              offLabel=""
            />
          </div>
        </div>

        <div className="settings-item">
          <div className="settings-item_label">
            <h4>{t('messages')}</h4>
            <span>{t('promotionsAndAnnouncements')}</span>
          </div>
          <div className="settings-item_content">
            <Switch
              checked={userSettings.subscribedToMessages}
              onChange={handleSubscribedToMessagesChange}
              onLabel=""
              offLabel=""
            />
          </div>
        </div>

        <div className="separator-xl"></div>

        <h3>{t('account')}</h3>
        <div
          className="settings-item clickable"
          onClick={() => setShowDeleteDialog(true)}
        >
          <div className="settings-item_label">
            <h4>{t('request_delete_account')}</h4>
          </div>
          <div className="settings-item_content">
            <ChevronRight />
          </div>
        </div>
      </div>
      {showDeleteDialog && (
        <DeleteAccountConfirmationDialog
          close={() => setShowDeleteDialog(false)}
        />
      )}
    </div>
  );
}
