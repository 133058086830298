import { ajax } from 'ajax/ajax';
import { getLocale } from 'locales/utils';
import * as _ from 'lodash';
import * as React from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { AdContext, IAdContext, IAdData } from './adContext';
import { CacheContext } from './cacheContext';
import {
  DealerListContext,
  IDealerListContext,
  IDealerListData,
} from './dealerListContext';
import {
  EquipmentListContext,
  IEquipmentListContext,
  IEquipmentListData,
} from './equipmentListContext';
import { GlobalErrorContext } from './globalErrorContext';
import {
  GlobalVideoContext,
  IGlobalVideoContext,
  IGlobalVideoState,
} from './globalVideoContext';
import { InboxContext } from './inboxContext';
import { LayoutContext } from './layoutContext';
import {
  IMaintenanceHistoryContext,
  IMaintenanceHistoryData,
  MaintenanceHistoryContext,
} from './maintenanceHistoryContext';
import { NotificationContext } from './notificationContext';
import { PushNotificationContext } from './pushNotificationContext';
import {
  IUserInfoContext,
  IUserInfoData,
  UserInfoContext,
} from './userInfoContext';
import {
  IUserLocation,
  IUserLocationContext,
  UserLocationContext,
} from './userLocationContext';
import {
  IUserSettingsContext,
  IUserSettingsState,
  UserSettingsContext,
} from './userSettingsContext';

let notificationIndex = 0;
let layoutClassNameIndex = 0;

export function ReactContextContainer(props: { children: JSX.Element }) {
  const { i18n } = useTranslation();

  const [classNames, setClassNames] = React.useState<
    { class: string; index: number }[]
  >([]);
  const [userInfo, setUserInfo] = React.useState({} as IUserInfoData);

  const userInfoContextValue = React.useMemo(() => {
    return {
      userInfo: userInfo,
      setUserInfo: setUserInfo,
    };
  }, [userInfo, setUserInfo]) as IUserInfoContext;

  const [userLocation, setUserLocation] = React.useState({} as IUserLocation);

  const userLocationContextValue = React.useMemo(() => {
    return {
      userLocation: userLocation,
      setUserLocation: setUserLocation,
    };
  }, [userLocation, setUserLocation]) as IUserLocationContext;

  const [equipmentList, setEquipmentList] = React.useState({
    searchValue: '',
  } as IEquipmentListData);
  const equipmentListContextValue = React.useMemo(() => {
    return {
      equipmentListData: equipmentList,
      setEquipmentListData: setEquipmentList,
    };
  }, [equipmentList, setEquipmentList]) as IEquipmentListContext;

  const [maintenanceHistory, setMaintenanceHistory] = React.useState(
    {} as IMaintenanceHistoryData,
  );
  const maintenanceHistoryContextValue = React.useMemo(() => {
    return {
      maintenanceHistoryData: maintenanceHistory,
      setMaintenanceHistoryData: setMaintenanceHistory,
    };
  }, [maintenanceHistory, setMaintenanceHistory]) as IMaintenanceHistoryContext;

  const [dealerList, setDealerList] = React.useState({} as IDealerListData);
  const dealerListContextValue = React.useMemo(() => {
    return {
      dealerListData: dealerList,
      setDealerListData: setDealerList,
    };
  }, [dealerList, setDealerList]) as IDealerListContext;

  const [globalVideoState, setGlobalVideoState] = React.useState({
    video: {
      url: '',
      name: '',
    },
    isModalOpen: false,
    isPiPEnabled: false,
  } as IGlobalVideoState);
  const globalVideoContextValue = React.useMemo(() => {
    return {
      globalVideoStateData: globalVideoState,
      setGlobalVideoStateData: setGlobalVideoState,
    };
  }, [globalVideoState, setGlobalVideoState]) as IGlobalVideoContext;

  const [userSettings, setUserSettings] = React.useState({
    measurementUnit: 'US',
    subscribedToAlerts: true,
    subscribedToMessages: true,
    subscribedToNotifications: true,
  } as IUserSettingsState);
  const userSettingsContextValue = React.useMemo(() => {
    return {
      userSettings: userSettings,
      setUserSettings: setUserSettings,
    };
  }, [userSettings, setUserSettings]) as IUserSettingsContext;

  React.useEffect(() => {
    (async () => {
      try {
        const { data } = await ajax.get(`${ajax.appBaseUrl}/api/user/settings`);

        if (data) {
          setUserSettings(data.settings);
        }
      } catch (ex) {
        if (window.navigator.language.includes('-US')) {
          setUserSettings({ ...userSettings, measurementUnit: 'US' });
          return;
        } else {
          setUserSettings({ ...userSettings, measurementUnit: 'METRIC' });
          return;
        }
      }
    })();
  }, []);

  const [adList, setAdList] = React.useState({} as IAdData);
  const adContextValue = React.useMemo(() => {
    return {
      adData: adList,
      setAdData: setAdList,
    };
  }, [adList, setAdList]) as IAdContext;

  const [notifications, setNotifications] = React.useState<any>([]);
  const [snackbarNotification, setSnackbarNotification] = React.useState<any>();
  const [pushNotifications, setPushNotifications] = React.useState<any>([]);
  const [inbox, setInbox] = React.useState<any>([]);

  const [apiModels, setApiModels] = React.useState<any>(null);
  const [lastLocale, setLastLocale] = React.useState(getLocale());
  const [showErrorModal, setShowErrorModal] = React.useState(false);

  const getApiModels = async () => {
    const currentLocale = getLocale();

    if (apiModels != null && currentLocale === lastLocale) {
      return apiModels;
    }

    try {
      const response = await ajax.get(`${ajax.appBaseUrl}/api/models`, {
        headers: {
          Version: '2023_R01',
        },
      });

      if (response.status == 200) {
        const result = {
          categories: response.data?.categories || [],
          models: response.data?.models || [],
        };
        setApiModels(result);

        if (currentLocale !== lastLocale) {
          setLastLocale(currentLocale);
        }

        return result;
      }
    } catch (ex) {
      setShowErrorModal(true);
    }

    return { categories: [], models: [] };
  };

  const removeNotification = index => {
    setNotifications(notifications =>
      notifications.filter(notification => notification.index !== index),
    );
  };

  const removeSnackbarNotification = () => {
    setSnackbarNotification(null);
  };

  const addNotification = (element, timer) => {
    const index = notificationIndex;
    notificationIndex = notificationIndex + 1;
    setNotifications(prevNotifications => [
      ...prevNotifications,
      {
        index: index,
        element: <div onClick={() => removeNotification(index)}>{element}</div>,
      },
    ]);

    if (timer) {
      setTimeout(() => {
        removeNotification(index);
      }, timer);
    }
    return index;
  };

  const addSnackbarNotification = (element, timer) => {
    setSnackbarNotification(element);

    if (timer) {
      setTimeout(() => {
        removeSnackbarNotification();
      }, timer);
    }
  };

  const removePushNotification = id => {
    setPushNotifications(notifications =>
      notifications.filter(notification => notification !== id),
    );
  };

  const addPushNotification = id => {
    setPushNotifications(pushNotifications.concat(id));
  };

  const addErrorModal = () => {
    setShowErrorModal(true);
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  React.useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  return (
    <>
      <InboxContext.Provider value={{ inbox, setInbox }}>
        <UserLocationContext.Provider value={userLocationContextValue}>
          <DealerListContext.Provider value={dealerListContextValue}>
            <UserInfoContext.Provider value={userInfoContextValue}>
              <I18nextProvider i18n={i18n}>
                <LayoutContext.Provider
                  value={{
                    classNames,
                    setClassNames: classNames => {
                      const index = layoutClassNameIndex;
                      layoutClassNameIndex += 1;
                      setClassNames(classes =>
                        classes.concat({
                          class: classNames,
                          index: index as number,
                        }),
                      );

                      return index;
                    },
                    removeClassNames: index => {
                      if (_.isNumber(index)) {
                        setClassNames(classes =>
                          _.filter(classes, cls => cls.index !== index),
                        );
                      }
                    },
                    removeSpecificClass: className => {
                      setClassNames(classes =>
                        _.filter(classes, cls => cls.class !== className),
                      );
                    },
                  }}
                >
                  <AdContext.Provider value={adContextValue}>
                    <NotificationContext.Provider
                      value={{
                        addNotification,
                        addSnackbarNotification,
                        removeNotification,
                        removeSnackbarNotification,
                        notifications,
                        snackbarNotification,
                      }}
                    >
                      <PushNotificationContext.Provider
                        value={{
                          addPushNotification,
                          removePushNotification,
                          pushNotifications,
                        }}
                      >
                        <GlobalErrorContext.Provider
                          value={{
                            addErrorModal,
                            closeErrorModal,
                            showErrorModal,
                          }}
                        >
                          <MaintenanceHistoryContext.Provider
                            value={maintenanceHistoryContextValue}
                          >
                            <EquipmentListContext.Provider
                              value={equipmentListContextValue}
                            >
                              <GlobalVideoContext.Provider
                                value={globalVideoContextValue}
                              >
                                <UserSettingsContext.Provider
                                  value={userSettingsContextValue}
                                >
                                  <CacheContext.Provider
                                    value={{ getApiModels, setApiModels }}
                                  >
                                    {props.children}
                                  </CacheContext.Provider>
                                </UserSettingsContext.Provider>
                              </GlobalVideoContext.Provider>
                            </EquipmentListContext.Provider>
                          </MaintenanceHistoryContext.Provider>
                        </GlobalErrorContext.Provider>
                      </PushNotificationContext.Provider>
                    </NotificationContext.Provider>
                  </AdContext.Provider>
                </LayoutContext.Provider>
              </I18nextProvider>
            </UserInfoContext.Provider>
          </DealerListContext.Provider>
        </UserLocationContext.Provider>
      </InboxContext.Provider>
    </>
  );
}
