import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import {
  EquipmentListContext,
  EquipmentTabs,
} from 'app/components/ReactContexts/equipmentListContext';
import SearchIcon from 'assets/images/ic_search.svg';
import SearchIconBack from 'assets/images/ic_search_back.svg';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { AddEquipment } from '../AddEquipment';

import GeofenceMenuIcon from 'assets/images/ic_btn_geofences.svg';

import ExpandMenuIcon from 'assets/images/chevron_down.svg';
import RetractMenuIcon from 'assets/images/chevron_up.svg';

import { FilterCard } from 'app/components/FilterCard';
import { ListAndMap } from 'app/components/ListAndMap';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useMeassurementUnit } from 'app/hooks/useMeasurementUnit';
import ClickOutside from 'app/widgets/ClickOutside';
import { geofenceic_nav_back as BackIcon } from 'app/widgets/SVGs';
import SecondaryBtnIcon from 'assets/images/ic_btn_add.svg';
import FilterIcon from 'assets/images/ic_btn_filter.svg';
import _ from 'lodash';
import { IGeofenceEntry } from 'types/IGeofenceEntry';
import { GeofenceEquipmentCard } from './GeofenceEquipmentCard';
import { GeofenceGeofenceCard } from './GeofenceGeofenceCard';

interface IGeofencesMenu {}

export function GeofencesMenu(props: IGeofencesMenu) {
  const { equipmentListData: data, setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);
  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false);
  const [verticalEllipsisMenuIsExpanded, setVerticalEllipsisMenuIsExpanded] =
    React.useState(false);

  const setSearchValue = (newValue: string) =>
    setData(oldData => ({ ...oldData, searchValue: newValue }));
  const searchValue = data.searchValue;

  const [searchBarExtended, setSearchBarExtended] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const { t } = useTranslation();

  const scrolableRef = React.useRef(null as null | HTMLDivElement);
  const posRef = React.useRef({ top: 0, left: 0, x: 0, y: 0 });

  const [filteredEquipmentList, setFilteredEquipmentList] = React.useState<any>(
    data.equipmentList,
  );
  const handleUpdateFilteredList = filteredList => {
    setFilteredEquipmentList(filteredList);
  };

  React.useEffect(() => {
    var filteredList = data.equipmentList;
    if (data.filtersList && data.filtersList.includes('active_fault_codes')) {
      filteredList = filteredList?.filter(
        f =>
          f.telematics &&
          f.telematics.faultCodes &&
          f.telematics?.faultCodes?.length > 0,
      );
    }

    if (data.filtersList && data.filtersList.includes('machine_running')) {
      filteredList = filteredList?.filter(
        f => f.telematics && f.telematics.engineRunning,
      );
    }

    if (data.filtersList && data.filtersList.includes('machine_in_transport')) {
      filteredList = filteredList?.filter(
        f => f.telematics && f.telematics.motionState == 'in-transport',
      );
    }

    if (data.filtersList && data.filtersList.includes('restart_inhibited')) {
      filteredList = filteredList?.filter(
        f =>
          f.telematics &&
          f.telematics.restartInhibitStatus != null &&
          f.telematics.restartInhibitStatus?.equipmentStatus ==
            'RestartDisabled',
      );
    }

    setFilteredEquipmentList(filteredList);
  }, [data.filtersList]);

  const mouseMoveHandler = function (e) {
    // How far the mouse has been moved
    const dx = e.clientX - posRef.current.x;
    const dy = e.clientY - posRef.current.y;

    // Scroll the element
    scrolableRef.current!.scrollTop = posRef.current.top - dy;
    scrolableRef.current!.scrollLeft = posRef.current.left - dx;
  };

  const mouseUpHandler = function () {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
    if (!scrolableRef.current) {
      return;
    }
    scrolableRef.current!.style.cursor = 'grab';
    scrolableRef.current!.style.removeProperty('user-select');
  };

  const mouseDownHandler = function (e) {
    posRef.current = {
      // The current scroll
      left: scrolableRef.current!.scrollLeft,
      top: scrolableRef.current!.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    scrolableRef.current!.style.cursor = 'grabbing';
    scrolableRef.current!.style.userSelect = 'none';

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const updateEquipmentList = (
    equipmentList,
    selectedIndex,
    selectedEquipment,
  ) => {
    if (selectedIndex >= 0) {
      setData({
        ...data,
        equipmentList,
        selectedIndex,
        selectedEquipment,
      });
    } else {
      setData({
        ...data,
        equipmentList,
      });
    }
  };

  const measurementUnit = useMeassurementUnit();

  const { classNames, setClassNames, removeSpecificClass, removeClassNames } =
    React.useContext(LayoutContext);
  const isMobile = useIsMobile();
  React.useEffect(() => {
    let index: number = setClassNames('layout-geofences');

    if (!isMobile) {
      setClassNames('geofences-menu-extended');
    }

    return () => {
      removeClassNames(index);
    };
  }, []);

  const menuIsExtended = classNames.find(
    el => el.class === 'geofences-menu-extended',
  );

  const expandMenu = () => {
    setClassNames('geofences-menu-extended');
  };

  const retractMenu = () => {
    removeSpecificClass('geofences-menu-extended');
  };

  React.useEffect(() => {
    return () => {
      removeSpecificClass('geofences-menu-extended');
    };
  }, []);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        setShowAddDialog(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <div className="equipment-list geofences-list">
      {showAddDialog && (
        <Dialog className="modal-equipment modal-fixed-button">
          <AddEquipment
            updateEquipmentList={updateEquipmentList}
            close={() => setShowAddDialog(false)}
          />
        </Dialog>
      )}
      {showFilter && (
        <ClickOutside
          onClickOutside={() => {
            setShowFilter(false);
          }}
        >
          <FilterCard updateFilteredList={handleUpdateFilteredList} />
        </ClickOutside>
      )}
      <div className="equipment-list-top-bar dealers-top-bar">
        <ListAndMap isMapMode={true} />
        <div
          className={`site-search site-search-desktop right-to-left ${
            searchBarExtended ? 'open' : ''
          }`}
        >
          <span
            className="search-icon"
            onClick={() => {
              if (searchBarExtended) {
                setSearchValue('');
              }
              setSearchBarExtended(!searchBarExtended);
            }}
          >
            {searchBarExtended && <img alt="Search" src={SearchIconBack}></img>}
            {!searchBarExtended && <img alt="Search" src={SearchIcon}></img>}
          </span>
          <div className="search-input">
            {searchBarExtended && (
              <div>
                <div>
                  <Input
                    autoFocus
                    id="search"
                    name="search"
                    placeholder={t('search_equipment_geofence')}
                    value={searchValue}
                    onChange={newValue => {
                      setSearchValue(newValue.value);
                      if (newValue.value) {
                        setClassNames('geofences-menu-extended');
                      }
                    }}
                  />
                  {searchValue && (
                    <span
                      className="search-icon-close"
                      onClick={() => {
                        setSearchValue('');
                      }}
                    >
                      <svg
                        id="ic_close_small"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <rect id="Box" width="24" height="24" fill="none" />
                        <g id="Icon" transform="translate(-2078.936 -713.936)">
                          <line
                            id="Line_1"
                            data-name="Line 1"
                            x2="8"
                            y2="8"
                            transform="translate(2086.935 721.935)"
                            fill="none"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeWidth="1"
                          />
                          <line
                            id="Line_2"
                            data-name="Line 2"
                            x1="8"
                            y2="8"
                            transform="translate(2086.935 721.935)"
                            fill="none"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeWidth="1"
                          />
                        </g>
                      </svg>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <span
          className="back-button-mobile"
          onClick={() => {
            setSearchValue('');
            retractMenu();
          }}
        >
          <BackIcon />
        </span>
        {!menuIsExtended && (
          <span
            className="mobile-geofence-menu-expand"
            onClick={() => {
              expandMenu();
            }}
          >
            <img src={GeofenceMenuIcon} />
            <span>
              Kubota
              <b>
                <i>NOW</i>
              </b>{' '}
              & Geofences
            </span>
          </span>
        )}

        <div className="site-search site-search-mobile right-to-left">
          <span
            className="search-icon"
            onClick={() => {
              if (searchBarExtended) {
                setSearchValue('');
              }
              expandMenu();
            }}
          >
            <img alt="Search" src={SearchIcon}></img>
          </span>
          <div className="search-input">
            <div>
              <div>
                {menuIsExtended && (
                  <Input
                    autoFocus
                    ref={ref => {
                      ref?.focus();
                    }}
                    id="search"
                    name="search"
                    placeholder={t('search_equipment_geofence')}
                    value={searchValue}
                    onChange={newValue => {
                      setSearchValue(newValue.value);
                      if (newValue.value) {
                        setClassNames('geofences-menu-extended');
                      }
                    }}
                  />
                )}
                {searchValue && (
                  <span
                    className="search-icon-close"
                    onClick={() => {
                      setSearchValue('');
                    }}
                  >
                    <svg
                      id="ic_close_small"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <rect id="Box" width="24" height="24" fill="none" />
                      <g id="Icon" transform="translate(-2078.936 -713.936)">
                        <line
                          id="Line_1"
                          data-name="Line 1"
                          x2="8"
                          y2="8"
                          transform="translate(2086.935 721.935)"
                          fill="none"
                          stroke="#000000"
                          strokeLinecap="round"
                          strokeWidth="1"
                        />
                        <line
                          id="Line_2"
                          data-name="Line 2"
                          x1="8"
                          y2="8"
                          transform="translate(2086.935 721.935)"
                          fill="none"
                          stroke="#000000"
                          strokeLinecap="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <Button
          className={`button-plus k-item k-menu-item filter-equipment-button ${
            searchBarExtended ? 'search-opened' : ''
          }`}
          onClick={() => setShowFilter(true)}
          themeColor="secondary"
        >
          <span className="btn_icon image-with-dot">
            <img src={FilterIcon} alt="Secodary btn icon" />
            {data.filtersList && data.filtersList.length > 0 && (
              <span className="red-dot"></span>
            )}
          </span>
        </Button>
        <span className="geofence-dot-menu-dropdown">
          <Button
            className={`button-plus k-item k-menu-item add-equipment-button ${
              searchBarExtended ? 'search-opened' : ''
            }`}
            onClick={() =>
              setVerticalEllipsisMenuIsExpanded(!verticalEllipsisMenuIsExpanded)
            }
            themeColor="secondary"
          >
            <span className="btn_icon">
              <img src={SecondaryBtnIcon} alt="Secodary btn icon" />
            </span>
          </Button>
          <ClickOutside
            className="geofence-dot-menu-dropdown-options"
            onClickOutside={() => {
              setVerticalEllipsisMenuIsExpanded(false);
            }}
          >
            <div>
              {verticalEllipsisMenuIsExpanded && (
                <>
                  <span
                    onClick={() => {
                      setShowAddDialog(true);
                    }}
                  >
                    <svg
                      id="ic_btn_equipment"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Vector"
                        d="M.16,14.607l9.086,5.165,8.6-5.177,0-9.419L9.163,0,.241,5Z"
                        transform="translate(3 2.11)"
                        fill="none"
                        stroke="#1a1a1a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.2"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M6,3A3,3,0,1,1,3,0,3,3,0,0,1,6,3Z"
                        transform="translate(9 9)"
                        fill="none"
                        stroke="#1a1a1a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.2"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0H24V24H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </svg>{' '}
                    &nbsp;
                    <span>{t('add_equipment')}</span>
                  </span>
                  <span
                    onClick={() => {
                      setData(oldProps => ({
                        ...oldProps,
                        isDrawing: true,
                      }));
                    }}
                  >
                    <svg
                      id="ic_btn_geofences"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <rect id="Box" width="24" height="24" fill="none" />
                      <g id="icon" transform="translate(4.5 2.5)">
                        <path
                          id="Map"
                          d="M7.5,6.712c-4.135,0-7.5-1.57-7.5-3.5C0,1.816,1.773.556,4.518,0A39.575,39.575,0,0,0,7.5,3.462,39.234,39.234,0,0,0,10.481,0C13.227.556,15,1.817,15,3.212,15,5.142,11.635,6.712,7.5,6.712Z"
                          transform="translate(0 12.288)"
                          fill="none"
                          stroke="#1a1a1a"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                        <g id="Pin" transform="translate(1.5)">
                          <path
                            id="Path"
                            d="M5188,1656c0,3.7-6,9.75-6,9.75s-6-6.047-6-9.75a6,6,0,0,1,12,0Z"
                            transform="translate(-5176 -1650)"
                            fill="none"
                            stroke="#1a1a1a"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.2"
                          />
                          <circle
                            id="Path-2"
                            data-name="Path"
                            cx="2.5"
                            cy="2.5"
                            r="2.5"
                            transform="translate(3.5 3.5)"
                            fill="none"
                            stroke="#1a1a1a"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                        </g>
                      </g>
                    </svg>{' '}
                    &nbsp;
                    <span>{t('add_geofence')}</span>
                  </span>
                </>
              )}
            </div>
          </ClickOutside>
        </span>
        {menuIsExtended && (
          <div className="row-2 tabs">
            <div
              className={`col-xxs-6 dealers-list-tab ${
                data.equipmentTab !== EquipmentTabs.Geofences
                  ? 'dealers-list-tab-selected'
                  : ''
              }`}
              onClick={() => {
                setData(oldState => ({
                  ...oldState,
                  equipmentTab: EquipmentTabs.Equipment,
                }));
              }}
            >
              <span
                dangerouslySetInnerHTML={{ __html: t('kubotanow_equipment') }}
              />
            </div>
            <div
              className={`col-xxs-6 dealers-list-tab ${
                data.equipmentTab === EquipmentTabs.Geofences
                  ? 'dealers-list-tab-selected'
                  : ''
              }`}
              onClick={() => {
                setData(oldState => ({
                  ...oldState,
                  equipmentTab: EquipmentTabs.Geofences,
                }));
              }}
            >
              {t('geofence_tab')}
            </div>
          </div>
        )}
      </div>
      {menuIsExtended && data.equipmentTab !== EquipmentTabs.Geofences && (
        <>
          {!_.some(
            filteredEquipmentList.filter(e => e.hasTelematics) || [],
            eq =>
              (eq?.nickName || '')
                .toLowerCase()
                .includes(searchValue.toLocaleLowerCase()) ||
              (eq?.pinOrSerial || '')
                .toLowerCase()
                .includes(searchValue.toLocaleLowerCase()) ||
              (eq?.model || '')
                .toLowerCase()
                .includes(searchValue.toLocaleLowerCase()),
          ) && (
            <>
              <div className="no-found-message">
                <h4>{t('no_results_found')}</h4>
                {data.filtersList && data.filtersList.length > 0 && (
                  <div>{t('no_equipment_found_message')}</div>
                )}
              </div>
            </>
          )}

          {filteredEquipmentList && (
            <div
              className="equipment-list-scroll-items"
              ref={scrolableRef}
              onMouseDown={filteredEquipmentList && mouseDownHandler}
            >
              {filteredEquipmentList &&
                filteredEquipmentList
                  ?.filter(e => e.hasTelematics)
                  .map((equipment: IEquipmentEntry, index) => {
                    if (
                      (equipment?.nickName || '')
                        .toLowerCase()
                        .includes(searchValue.toLocaleLowerCase()) ||
                      (equipment?.pinOrSerial || '')
                        .toLowerCase()
                        .includes(searchValue.toLocaleLowerCase()) ||
                      (equipment?.model || '')
                        .toLowerCase()
                        .includes(searchValue.toLocaleLowerCase())
                    ) {
                      return (
                        <GeofenceEquipmentCard
                          key={index}
                          equipment={equipment}
                          index={index}
                          measurementUnit={measurementUnit}
                        />
                      );
                    }
                    return null;
                  })}
            </div>
          )}
        </>
      )}

      {menuIsExtended && data.equipmentTab === EquipmentTabs.Geofences && (
        <>
          {!data?.geofenceList?.length && (
            <div className="equipment-list-scroll-items">
              <div className="dealers-list-empty-wrapper">
                <span className="dealers-list-empty-image">
                  <svg
                    id="ic_empty_geofences"
                    xmlns="http://www.w3.org/2000/svg"
                    width="120"
                    height="120"
                    viewBox="0 0 120 120"
                  >
                    <rect
                      id="BG"
                      width="120"
                      height="120"
                      rx="60"
                      fill="#fff"
                    />
                    <g id="icon" transform="translate(-5143 -1620.5)">
                      <path
                        id="Path"
                        d="M5182,1666l-9,18h60l-9-18"
                        transform="translate(0 25)"
                        fill="none"
                        stroke="#909090"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                      <g id="Pin" transform="translate(5185 1652)">
                        <path
                          id="Path-2"
                          data-name="Path"
                          d="M5212,1668c0,11.108-18,29.25-18,29.25s-18-18.142-18-29.25a18,18,0,0,1,36,0Z"
                          transform="translate(-5176 -1650)"
                          fill="none"
                          stroke="#909090"
                          stroke-linecap="round"
                          stroke-width="2"
                        />
                        <circle
                          id="Path-3"
                          data-name="Path"
                          cx="5.625"
                          cy="5.625"
                          r="5.625"
                          transform="translate(12.375 12.375)"
                          fill="none"
                          stroke="#909090"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                <h4>{t('no_geofences_yet')}</h4>
                <h5>{t('click_on_plus')}</h5>

                <Button
                  className={`k-item k-menu-item ${
                    searchBarExtended ? 'search-opened' : ''
                  }`}
                  onClick={() => {
                    setData(oldData => ({
                      ...oldData,
                      isDrawing: true,
                    }));
                  }}
                  themeColor="secondary"
                >
                  {t('plus_add_geofence')}
                </Button>
              </div>
            </div>
          )}

          {(data?.geofenceList?.length || 0) > 0 &&
            !_.some(
              data?.geofenceList as IGeofenceEntry[],
              (gf: IGeofenceEntry) =>
                gf.description
                  .toLocaleLowerCase()
                  .includes(searchValue.toLocaleLowerCase()),
            ) && (
              <div className="no-found-message">{t('no_results_found')}</div>
            )}

          {(data?.geofenceList?.length || 0) > 0 && (
            <div
              className="equipment-list-scroll-items"
              ref={scrolableRef}
              onMouseDown={data?.geofenceList && mouseDownHandler}
            >
              {data?.geofenceList &&
                data.geofenceList.map((geofence: IGeofenceEntry, index) => {
                  if (
                    (geofence?.description || '')
                      .toLocaleLowerCase()
                      .includes(searchValue.toLocaleLowerCase())
                  ) {
                    return (
                      <GeofenceGeofenceCard
                        key={`geofenceListCard` + geofence.id}
                        index={index}
                        geofence={geofence}
                        measurementUnit={'US'}
                      />
                    );
                  }
                  return null;
                })}
            </div>
          )}
        </>
      )}
      {menuIsExtended ? (
        <Button className="geofence-list-btn" onClick={retractMenu}>
          <img src={RetractMenuIcon} />
        </Button>
      ) : (
        <Button className="geofence-list-btn" onClick={expandMenu}>
          <img src={ExpandMenuIcon} />
        </Button>
      )}
    </div>
  );
}
