import useClickOutside from 'app/hooks/useClickOutisde';
import { LegendDialog } from './LegendDialog';
import InfoIcon from 'assets/images/ic_nav_info.svg';
import * as React from 'react';

export function MapLegendInfoControl() {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleClickOutside = React.useCallback(handleClose, [isDialogOpen]);
  useClickOutside(handleClickOutside);

  function handleClose() {
    if (isDialogOpen) {
      setIsDialogOpen(false);
    }
  }

  return (
    <>
      <img
        className="map-control-icon"
        onClick={() => setIsDialogOpen(true)}
        style={{ stroke: 'red', fill: 'red' }}
        src={InfoIcon}
      />

      <LegendDialog open={isDialogOpen} onClose={handleClose} />
    </>
  );
}
